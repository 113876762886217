import React from 'react'
import PropTypes from 'prop-types'

import _uniqueId from 'lodash/uniqueId'

import { css } from '@emotion/core'

import TransitionLink from 'gatsby-plugin-transition-link'

import styled from '@emotion/styled/macro'
import isPropValid from '@emotion/is-prop-valid'
import tw from 'twin.macro'
import { brandGreen } from '../utilities/colours'

import {
  outTransition as defaultOutTransition,
  inTransition as defaultInTransition,
  outDuration as defaultOutDuration,
  inDuration as defaultInDuration,
} from '../transitions/fade'

const Bg = styled.div`
  ${tw`relative bg-brand-green rounded-full shadow z-10 transform transition-all duration-200 ease-out`}
  width: 56px;
  height: 56px;
`

const IconHolder = styled.div`
  ${tw`absolute w-full h-full left-0 top-0 text-white flex justify-center items-center z-10 transition-all duration-200 ease-out`}
`

const StyledLink = styled(TransitionLink, {
  shouldForwardProp: prop => isPropValid(prop) || prop !== 'bgColour',
})`
  ${tw`inline-block relative pointer-events-auto`}
  width: 56px;
  height: 56px;

  ${props =>
    props.bgColour === 'white' &&
    `
    ${IconHolder} {
      color: black;
    }

    ${Bg} {
      background-color: white;
    }

    &:hover {
      ${IconHolder} {
        color: white !important;
      }
      ${Bg} {
        background-color:${brandGreen} !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'black' &&
    `
    ${IconHolder} {
      color: white;
    }

    ${Bg} {
      background-color: black;
    }

    &:hover {
      ${IconHolder} {
        color: black !important;
      }

      ${Bg} {
        background-color:white !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'transparent' &&
    `
    width: 40px;
    height: 40px;

    ${IconHolder} {
      color: black;
    }

    ${Bg} {
      width: 40px;
      height: 40px;
      background-color: transparent;
      box-shadow: none;
    }

    &:hover {
      ${IconHolder} {
        color: white !important;
      }

      ${Bg} {
        background-color:${brandGreen} !important;
        box-shadow: 0px 10px 25px #00000029;
      }
    }
  `}

  &:hover {
    ${IconHolder} {
      ${tw`text-black`}
    }

    ${Bg} {
      ${tw`scale-110 bg-white`}
    }

    label {
      opacity: 1;
      transform: translate(-50%, 12px);
    }
  }

  &:focus {
    outline: none !important;
  }
`

const StyledA = styled.a`
  ${tw`inline-block relative pointer-events-auto`}
  width: 56px;
  height: 56px;

  ${props =>
    props.bgColour === 'white' &&
    `
    ${IconHolder} {
      color: black;
    }

    ${Bg} {
      background-color: white;
    }

    &:hover {
      ${IconHolder} {
        color: white !important;
      }
      ${Bg} {
        background-color:${brandGreen} !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'black' &&
    `
    ${IconHolder} {
      color: white;
    }

    ${Bg} {
      background-color: black;
    }

    &:hover {
      ${IconHolder} {
        color: black !important;
      }

      ${Bg} {
        background-color:white !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'transparent' &&
    `
    width: 40px;
    height: 40px;

    ${IconHolder} {
      color: black;
    }

    ${Bg} {
      width: 40px;
      height: 40px;
      background-color: transparent;
      box-shadow: none;
    }

    &:hover {
      ${IconHolder} {
        color: white !important;
      }

      ${Bg} {
        background-color:${brandGreen} !important;
        box-shadow: 0px 10px 25px #00000029;
      }
    }
  `}

  &:hover {
    ${IconHolder} {
      ${tw`text-black`}
    }

    ${Bg} {
      ${tw`scale-110 bg-white`}
    }

    label {
      opacity: 1;
      transform: translate(-50%, 12px);
    }
  }

  &:focus {
    outline: none !important;
  }
`

const StyledButton = styled.button`
  ${tw`inline-block relative pointer-events-auto`}
  width: 56px;
  height: 56px;

  ${props =>
    props.bgColour === 'white' &&
    `
    ${IconHolder} {
    color: black;
    }

    ${Bg} {
      background-color: white;
    }

    &:hover {
      ${IconHolder} {
        color: white !important;
      }

      ${Bg} {
        background-color:${brandGreen} !important;
      }

      label {
        opacity: 1;
        transform: translate(-50%, 12px);
      }
    }
  `}

  ${props =>
    props.bgColour === 'black' &&
    `
    ${IconHolder} {
    color: white;
    }
    ${Bg} {
      background-color: black;
    }

    &:hover {
      color: black !important;

      ${Bg} {
        background-color:white !important;

      }
    }
  `}

${props =>
  props.bgColour === 'transparent' &&
  `
    width: 40px;
    height: 40px;

    ${IconHolder} {
      color: black;
    }

    ${Bg} {
      width: 40px;
      height: 40px;
      background-color: transparent;
      box-shadow: none;
    }

    &:hover {
      ${IconHolder} {
        color: white !important;
      }

      ${Bg} {
        background-color:${brandGreen} !important;
        box-shadow: 0px 10px 25px #00000029;
      }
    }
  `}

  &:hover {
    ${IconHolder} {
      ${tw`text-black`}
    }

    ${Bg} {
      ${tw`scale-110 bg-white`}
    }
  }

  &:focus {
    outline: none !important;
  }
`

const ConditionalLink = ({
  to,
  isExternal,
  bgColour,
  outTransition,
  outDuration,
  inTransition,
  inDuration,
  children,
}) =>
  isExternal ? (
    <StyledA
      className="group"
      href={to}
      bgColour={bgColour}
      target={isExternal ? '_blank' : '_self'}
    >
      {children}
    </StyledA>
  ) : (
    <StyledLink
      exit={{ trigger: outTransition, length: outDuration }}
      entry={{ trigger: inTransition, length: inDuration, delay: outDuration }}
      className="group"
      to={to}
      bgColour={bgColour}
    >
      {children}
    </StyledLink>
  )

const CircleButton = ({
  to,
  isExternal,
  label,
  bgColour,
  onClick,
  outTransition,
  outDuration,
  inTransition,
  inDuration,
  children,
}) => {
  const id = _uniqueId('circle-button-')

  if (onClick) {
    return (
      <StyledButton bgColour={bgColour} onClick={onClick}>
        <Bg />
        <IconHolder>{children}</IconHolder>
        {label && (
          <label
            htmlFor={id}
            css={css`
              bottom: -15px;
            `}
            tw="text-xxs absolute w-16 left-1/2 transform -translate-x-1/2 opacity-0 transition duration-300 text-center"
          >
            {label}
          </label>
        )}
      </StyledButton>
    )
  } else {
    return (
      <ConditionalLink
        id={id}
        to={to}
        isExternal={isExternal}
        bgColour={bgColour}
        outTransition={outTransition}
        outDuration={outDuration}
        inTransition={inTransition}
        inDuration={inDuration}
      >
        <Bg />
        <IconHolder>{children}</IconHolder>
        {label && (
          <label
            htmlFor={id}
            css={css`
              bottom: -15px;
            `}
            tw="text-xxs absolute w-16 left-1/2 transform -translate-x-1/2 opacity-0 transition duration-300 text-center"
          >
            {label}
          </label>
        )}
      </ConditionalLink>
    )
  }
}

CircleButton.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  bgColour: PropTypes.string,
  onClick: PropTypes.func,
  outTransition: PropTypes.func,
  outDuration: PropTypes.number,
  inTransition: PropTypes.func,
  inDuration: PropTypes.number,
  children: PropTypes.node,
}

CircleButton.defaultProps = {
  to: '/',
  label: '',
  bgColour: 'green',
  onClick: null,
  outTransition: defaultOutTransition,
  outDuration: defaultOutDuration,
  inTransition: defaultInTransition,
  inDuration: defaultInDuration,
  children: null,
}

export default CircleButton
