import React from 'react'
import PropTypes from 'prop-types'

import TransitionLink from 'gatsby-plugin-transition-link'

import { css } from '@emotion/core'
import isPropValid from '@emotion/is-prop-valid'
import tw from 'twin.macro'
import styled from '@emotion/styled/macro'
import { brandGreen } from '../utilities/colours'

import {
  outTransition as defaultOutTransition,
  inTransition as defaultInTransition,
  outDuration as defaultOutDuration,
  inDuration as defaultInDuration,
} from '../transitions/fade'

const Bg = styled.div`
  ${tw`absolute w-full h-full left-0 top-0 bg-brand-green rounded-full shadow z-10 transform transition-transform duration-200 ease-out`}
`

const StyledLink = styled(TransitionLink, {
  shouldForwardProp: prop =>
    isPropValid(prop) || (prop !== 'isSpeechBubble' && prop !== 'bgColour'),
})`
  ${tw`relative text-base md:text-sm font-normal text-white pointer-events-auto inline-block`}
  height:56px;

  ${props =>
    props.bgColour === 'white' &&
    `
    color: black;

    ${Bg} {
      background-color: white;
    }

    &:hover {
      color: white !important;

      ${Bg} {
        background-color:${brandGreen} !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'black' &&
    `
    color: white;

    ${Bg} {
      background-color: black;
    }

    &:hover {
      color: black !important;

      ${Bg} {
        background-color:white !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'transparent' &&
    `
    color: black;

    ${Bg} {
      background-color: transparent;
      box-shadow: none;
    }

    &:hover {
      color: white !important;

      ${Bg} {
        background-color:${brandGreen} !important;
      }
    }
  `}

  &:hover {
    ${tw`text-black`}

    ${Bg} {
      ${tw`scale-110 bg-white`}
    }

    ${props =>
      props.isSpeechBubble &&
      `
      ${Bg}:after {
        border-top: 20px solid white;
      }
    `}
  }

  ${props =>
    props.isSpeechBubble &&
    `
    ${Bg}:after {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      bottom: -18px;
      width: 0;
      height: 0;
      content: "";
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid rgba(63,212,128,1);
    }
  `}
`

const StyledA = styled.a`
  ${tw`relative text-base md:text-sm font-normal text-white pointer-events-auto inline-block`}
  height:56px;

  ${props =>
    props.bgColour === 'white' &&
    `
    color: black;

    ${Bg} {
      background-color: white;
    }

    &:hover {
      color: white !important;

      ${Bg} {
        background-color:${brandGreen} !important;
      }
    }
  `}

  ${props =>
    props.bgColour === 'black' &&
    `
    color: white;

    ${Bg} {
      background-color: black;
    }

    &:hover {
      color: black !important;

      ${Bg} {
        background-color:white !important;
      }
    }
  `}

${props =>
  props.bgColour === 'transparent' &&
  `
    color: black;

    ${Bg} {
      background-color: transparent;
      box-shadow: none;
    }

    &:hover {
      color: white !important;

      ${Bg} {
        background-color:${brandGreen} !important;
        box-shadow: 0px 10px 25px #00000029;
      }
    }
  `}

  &:hover {
    ${tw`text-black`}

    ${Bg} {
      ${tw`scale-110 bg-white`}
    }

    ${props =>
      props.isSpeechBubble &&
      `
      ${Bg}:after {
        border-top: 20px solid white;
        box-shadow: 0px 10px 25px #00000029;
      }
    `}
  }

  ${props =>
    props.isSpeechBubble &&
    `
    ${Bg}:after {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      bottom: -18px;
      width: 0;
      height: 0;
      content: "";
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid rgba(63,212,128,1);
    }
  `}
`

const ConditionalLink = ({
  to,
  isExternal,
  isSpeechBubble,
  bgColour,
  outTransition,
  outDuration,
  inTransition,
  inDuration,
  children,
}) =>
  isExternal ? (
    <StyledA
      className="group"
      href={to}
      isSpeechBubble={isSpeechBubble}
      bgColour={bgColour}
    >
      {children}
    </StyledA>
  ) : (
    <StyledLink
      exit={{ trigger: outTransition, length: outDuration }}
      entry={{ trigger: inTransition, length: inDuration, delay: outDuration }}
      className="group"
      to={to}
      isSpeechBubble={isSpeechBubble}
      bgColour={bgColour}
    >
      {children}
    </StyledLink>
  )

const ButtonLink = ({
  to,
  text,
  hoverText,
  isExternal,
  isSpeechBubble,
  outTransition,
  outDuration,
  inTransition,
  inDuration,
  bgColour,
  children,
}) => (
  <ConditionalLink
    to={to}
    isExternal={isExternal}
    isSpeechBubble={isSpeechBubble}
    bgColour={bgColour}
    outTransition={outTransition}
    outDuration={outDuration}
    inTransition={inTransition}
    inDuration={inDuration}
  >
    <Bg />
    <div
      tw="relative h-full px-6 inline-flex justify-center items-center z-20"
      css={css`
        top: -2px;
      `}
    >
      <div tw="opacity-100 group-hover:opacity-0 transition-opacity duration-300 flex justify-center items-center">
        {children}
        <div>{text}</div>
      </div>
      <div tw="opacity-0 group-hover:opacity-100 transition-opacity duration-300 w-full text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
        {children}
        <div>{hoverText ? hoverText : text}</div>
      </div>
    </div>
  </ConditionalLink>
)

ButtonLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  isExternal: PropTypes.bool,
  isSpeechBubble: PropTypes.bool,
  bgColour: PropTypes.string,
  outTransition: PropTypes.func,
  outDuration: PropTypes.number,
  inTransition: PropTypes.func,
  inDuration: PropTypes.number,
  children: PropTypes.node,
}

ButtonLink.defaultProps = {
  to: '/',
  text: 'Read more',
  hoverText: null,
  isExternal: false,
  isSpeechBubble: false,
  bgColour: 'green',
  outTransition: defaultOutTransition,
  outDuration: defaultOutDuration,
  inTransition: defaultInTransition,
  inDuration: defaultInDuration,
  children: null,
}

export default ButtonLink
