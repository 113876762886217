import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'twin.macro'

import CircleButton from './circle-button'

const SocialIcons = () => {
  const { social } = useStaticQuery(graphql`
    {
      social: craftSocialGlobalSet {
        socialIcons {
          ... on Craft_socialIcons_socialAccount_BlockType {
            socialAccountName
            socialAccountUrl
            id
          }
        }
      }
    }
  `)

  return (
    <div>
      {social.socialIcons.map(icon => (
        <div key={icon.id} tw="inline-block no-underline mr-0">
          <CircleButton
            to={icon.socialAccountUrl}
            bgColour="transparent"
            isExternal
          >
            <FontAwesomeIcon icon={['fab', icon.socialAccountName]} />
          </CircleButton>
        </div>
      ))}
    </div>
  )
}

export default SocialIcons
