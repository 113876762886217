import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import tw from 'twin.macro'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonLink from './button-link'
import SocialIcons from './social-icons'

const SiteFoot = () => {
  const { contactDetails } = useStaticQuery(graphql`
    {
      contactDetails: craftContactDetailsGlobalSet {
        address
        phoneNumber
        eMailAddress
      }
    }
  `)

  return (
    <footer tw="pt-12 pb-8 text-sm font-normal">
      <div tw="container">
        <div tw="flex flex-wrap lg:flex-no-wrap lg:justify-between -mx-4">
          <div tw="w-full lg:w-auto px-4 mb-4 lg:mb-0 text-center lg:text-left">
            &copy; {new Date().getFullYear()} Sheila Bird Studio.
            <br />
            All rights reserved.
          </div>
          <div tw="w-full lg:w-auto px-4 mb-4 lg:mb-0 text-center lg:text-left">
            {contactDetails.eMailAddress && (
              <div tw="lg:-ml-8 lg:-mt-2">
                <ButtonLink
                  text="Drop us a line"
                  hoverText="Drop us a line"
                  to={`mailto:${contactDetails.eMailAddress}`}
                  isExternal
                  bgColour="transparent"
                >
                  <FontAwesomeIcon tw="mr-2" icon={['fas', 'envelope']} />
                </ButtonLink>
              </div>
            )}
            {contactDetails.phoneNumber && (
              <div tw="lg:-ml-8">
                <ButtonLink
                  text={contactDetails.phoneNumber}
                  hoverText={contactDetails.phoneNumber}
                  to={`tel:${contactDetails.phoneNumber}`}
                  isExternal
                  bgColour="transparent"
                >
                  <FontAwesomeIcon tw="mr-2" icon={['fas', 'phone']} />
                </ButtonLink>
              </div>
            )}
          </div>
          <div tw="w-full lg:w-auto px-4 mb-4 lg:mb-0 text-center lg:text-left">
            {contactDetails.address && (
              <address
                tw="not-italic"
                dangerouslySetInnerHTML={{
                  __html: contactDetails.address,
                }}
              ></address>
            )}
          </div>
          <div tw="w-full lg:w-auto px-4 flex flex-col justify-between text-center lg:text-right">
            <div tw="mb-4 lg:mb-0 text-base">
              <SocialIcons />
            </div>
            <div tw="text-xs">
              Site by{' '}
              <a
                href="https://www.studiotreble.com/"
                target="_blank"
                rel="noreferrer"
              >
                Studio Treble
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default SiteFoot
